import { UI_SET_LOADING_ACTION } from '../actions/actionTypes'

const initialState = {
  loadingAction: null,
}

function uiReducer(state = initialState, action) {
  switch (action.type) {
    case UI_SET_LOADING_ACTION:
      return {
        ...state,
        loadingAction: action.payload,
      }
    default:
      return state
  }
}

export default uiReducer
