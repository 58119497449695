import {
  ORDER_SET_PRODUCT_SKU,
  ORDER_SET_PRODUCT_ART,
  ORDER_SET_QUANTITY,
  ORDER_SET_LIST,
  ORDER_SET_LIST_DATA,
} from '../actions/actionTypes'

const initialState = {
  product: undefined,
  productSku: undefined,
  productInfo: {},
  art: {
    front: undefined,
    back: undefined,
    none: false,
    envelope: undefined,
    noEnvelope: false,
  },
  quantity: 0,
  list: undefined,
  listPreview: undefined,
  listRemaining: 0,
  noList: false,
  mailType: undefined,
  npa: undefined,
}

function orderReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_SET_PRODUCT_SKU:
      return {
        ...state,
        productSku: action.payload.sku,
        product: action.payload.product,
        productInfo: action.payload.info,
      }
    case ORDER_SET_PRODUCT_ART:
      return {
        ...state,
        art: Object.assign(state.art, action.payload),
      }
    case ORDER_SET_QUANTITY:
      return {
        ...state,
        quantity: action.payload,
      }
    case ORDER_SET_LIST_DATA:
      return {
        ...state,
        listPreview: action.payload.preview,
        listRemaining: action.payload.remainingCount,
      }
    case ORDER_SET_LIST:
      return {
        ...state,
        list: action.payload.list,
        noList: action.payload.noList,
        listFilename: action.payload.listFilename,
        quantity: parseInt(
          action.payload.quantity ? action.payload.quantity : state.quantity
        ),
        mailType: action.payload.mailType,
        npa: action.payload.npa,
      }
    default:
      return state
  }
}

export default orderReducer
