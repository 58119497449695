// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-letters-confirm-js": () => import("/opt/build/repo/src/pages/letters/confirm.js" /* webpackChunkName: "component---src-pages-letters-confirm-js" */),
  "component---src-pages-letters-design-js": () => import("/opt/build/repo/src/pages/letters/design.js" /* webpackChunkName: "component---src-pages-letters-design-js" */),
  "component---src-pages-letters-envelope-js": () => import("/opt/build/repo/src/pages/letters/envelope.js" /* webpackChunkName: "component---src-pages-letters-envelope-js" */),
  "component---src-pages-letters-index-js": () => import("/opt/build/repo/src/pages/letters/index.js" /* webpackChunkName: "component---src-pages-letters-index-js" */),
  "component---src-pages-letters-list-js": () => import("/opt/build/repo/src/pages/letters/list.js" /* webpackChunkName: "component---src-pages-letters-list-js" */),
  "component---src-pages-postcards-confirm-js": () => import("/opt/build/repo/src/pages/postcards/confirm.js" /* webpackChunkName: "component---src-pages-postcards-confirm-js" */),
  "component---src-pages-postcards-design-js": () => import("/opt/build/repo/src/pages/postcards/design.js" /* webpackChunkName: "component---src-pages-postcards-design-js" */),
  "component---src-pages-postcards-index-js": () => import("/opt/build/repo/src/pages/postcards/index.js" /* webpackChunkName: "component---src-pages-postcards-index-js" */),
  "component---src-pages-postcards-list-js": () => import("/opt/build/repo/src/pages/postcards/list.js" /* webpackChunkName: "component---src-pages-postcards-list-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

