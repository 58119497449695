import {
  compose,
  createStore as reduxCreateStore,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'

const composeEnhancers =
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const createStore = () =>
  reduxCreateStore(reducers, composeEnhancers(applyMiddleware(thunk)))

export default createStore
